import PropTypes from "prop-types";
import { useCallback, useState, useMemo } from "react";

import SearchAutocomplete from "components/Search/SearchAutocomplete";
import SearchFieldContainer from "containers/SearchFieldContainer";

import appActions from "actions/app";
import { SEARCH_PLACEHOLDER, SEARCH_PLACEHOLDER_SHORT } from "constants/base";
import getCategoryText from "utils/entity/getCategoryText";
import getCategoryUrl from "utils/entity/getCategoryUrl";
import getCreatorName from "utils/entity/getCreatorName";
import getEpisodeName from "utils/entity/getEpisodeName";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import getPodcastName from "utils/entity/getPodcastName";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import getTagUrl from "utils/entity/getTagUrl";
import getUserDisplayName from "utils/entity/getUserDisplayName";
import getUserListName from "utils/entity/getUserListName";
import getUserListUrl from "utils/entity/getUserListUrl";
import getUserUrl from "utils/entity/getUserUrl";
import { getTypeFromSearchResult } from "utils/search";
import sendGAEvent from "utils/sendGAEvent";
import { getCreatorUrl } from "utils/url/creatorUrls";

import useActionCreators from "hooks/useActionCreators";
import useWindowSize from "hooks/useWindowSize";

const EntityNavigateSearch = (props) => {
  const {
    searchKey,
    placeholderSmall: passedPlaceholderSmall,
    placeholder: passedPlaceholder,
    types,
    forwardedRef,
    autoCompleteStyles,
    onResultClickOptions,
    mobile: passedMobile,
    analyticsVariables: passedAnalyticsVariables,
  } = props;

  const { isWindowSizeOrLess } = useWindowSize();

  const mobile =
    passedMobile !== undefined ? passedMobile : isWindowSizeOrLess("medium");
  const { navigateTo } = useActionCreators(appActions);
  const [lastTerm, setLastTerm] = useState(null);

  const analyticsVariables = useMemo(
    () => ({
      componentContext: "EntityNavigateSearch",
      ...(passedAnalyticsVariables || {}),
    }),
    [passedAnalyticsVariables]
  );

  const placeholder = useMemo(() => {
    if (mobile && (passedPlaceholderSmall || !passedPlaceholder)) {
      return passedPlaceholderSmall || SEARCH_PLACEHOLDER_SHORT;
    }

    return passedPlaceholder || SEARCH_PLACEHOLDER;
  }, [mobile, passedPlaceholder, passedPlaceholderSmall]);

  const handleSearchChange = useCallback(
    (searchProps) =>
      (value, ...rest) => {
        setLastTerm(value);
        searchProps.onSearchChange(value, ...rest);
      },
    []
  );

  const handleSelect = useCallback(
    (item, { cancelSearch }) => {
      cancelSearch();

      if (!item) {
        return;
      }

      // TODO: this is duplicated between here and TopSearchContainer, need to make this DRY

      switch (getTypeFromSearchResult(item)) {
        case "Podcast": {
          sendGAEvent({
            action: "podcastSearchResultClick",
            search_term: lastTerm,
            podcast_id: item.getIn(["_source", "id"]),
            podcast_name: getPodcastName(item.get("_source")),
          });
          if (onResultClickOptions.podcast) {
            onResultClickOptions.podcast(item.get("_source"), navigateTo);
          } else {
            navigateTo(getPodcastUrl(item.get("_source")));
          }
          break;
        }
        case "Episode": {
          sendGAEvent({
            action: "episodeSearchResultClick",
            search_term: lastTerm,
            podcast_id: item.getIn(["_source", "podcast", "id"]),
            podcast_name: getPodcastName(item.getIn(["_source", "podcast"])),
            episode_id: item.getIn(["_source", "id"]),
            episode_name: getEpisodeName(item.get("_source")),
          });
          if (onResultClickOptions.episode) {
            onResultClickOptions.episode(item.get("_source"), navigateTo);
          } else {
            navigateTo(
              getEpisodeUrl(item.get("_source"), {
                podcast: item.getIn(["_source", "podcast"]),
              })
            );
          }
          break;
        }
        case "Tag": {
          if (item.getIn(["_source", "type"]) === "category") {
            sendGAEvent({
              action: "categorySearchResultClick",
              search_term: lastTerm,
              entity_id: item.getIn(["_source", "id"]),
              category_name: getCategoryText(item.get("_source")),
            });
            if (onResultClickOptions.category) {
              onResultClickOptions.category(item.get("_source"), navigateTo);
            } else {
              navigateTo(getCategoryUrl(item.get("_source")));
            }
          } else if (item.getIn(["_source", "type"]) === "hashtag") {
            sendGAEvent({
              action: "hashtagSearchResultClick",
              search_term: lastTerm,
              entity_id: item.getIn(["_source", "id"]),
              hashtag_name: getCategoryText(item.get("_source")),
            });

            if (onResultClickOptions.tag) {
              onResultClickOptions.tag(item.get("_source"), navigateTo);
            } else {
              navigateTo(getTagUrl(item.get("_source")));
            }
          }
          break;
        }
        case "UserList": {
          sendGAEvent({
            action: "userlistSearchResultClick",
            search_term: lastTerm,
            entity_id: item.getIn(["_source", "id"]),
            userlist_name: getUserListName(item.get("_source")),
          });

          if (onResultClickOptions.userlist) {
            onResultClickOptions.userlist(item.get("_source"), navigateTo);
          } else {
            navigateTo(getUserListUrl(item.getIn(["_source"])));
          }
          break;
        }
        case "Creator": {
          sendGAEvent({
            action: "creatorSearchResultClick",
            search_term: lastTerm,
            entity_id: item.getIn(["_source", "id"]),
            creator_name: getCreatorName(item.get("_source")),
          });

          if (onResultClickOptions.creator) {
            onResultClickOptions.creator(item.get("_source"), navigateTo);
          } else {
            navigateTo(getCreatorUrl(item.get("_source")));
          }
          break;
        }
        case "User": {
          sendGAEvent({
            action: "userSearchResultClick",
            search_term: lastTerm,
            entity_id: item.getIn(["_source", "id"]),
            user_name: getUserDisplayName(item.get("_source")),
          });
          if (onResultClickOptions.user) {
            onResultClickOptions.user(item.get("_source"), navigateTo);
          } else {
            navigateTo(getUserUrl(item.get("_source")));
          }
          break;
        }

        case "FullSearch": {
          sendGAEvent({
            action: "fullSearchClick",
            entity_type: item.getIn(["_source", "type"]).replace(/s$/, ""),
            search_term: lastTerm,
          });

          if (onResultClickOptions.fullsearch) {
            onResultClickOptions.fullsearch(item.get("_source"), navigateTo);
          } else {
            navigateTo(
              `/search/${item.getIn(["_source", "type"])}/q/${item.getIn([
                "_source",
                "term",
              ])}`
            );
          }
          break;
        }
        default:
      }
    },
    [navigateTo, onResultClickOptions, lastTerm]
  );

  const renderSearch = useCallback(
    (searchProps) => (
      <SearchAutocomplete
        {...props}
        {...searchProps}
        placeholder={placeholder}
        styles={autoCompleteStyles}
        value={searchProps.search_term}
        onInputValueChange={handleSearchChange(searchProps)}
        onItemSelect={handleSelect}
        forwardedRef={forwardedRef}
        ariaLabel="Search by entity type"
      />
    ),
    [
      handleSearchChange,
      props,
      handleSelect,
      autoCompleteStyles,
      forwardedRef,
      placeholder,
    ]
  );

  return (
    <SearchFieldContainer
      searchKey={searchKey}
      types={types}
      analyticsVariables={analyticsVariables}
    >
      {renderSearch}
    </SearchFieldContainer>
  );
};

EntityNavigateSearch.propTypes = {
  searchKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  placeholderSmall: PropTypes.string,
  onResultClickOptions: PropTypes.object,
  autoCompleteStyles: PropTypes.object,
  types: PropTypes.array,
  forwardedRef: PropTypes.object,
  mobile: PropTypes.bool,
  analyticsVariables: PropTypes.object,
};

EntityNavigateSearch.defaultProps = {
  placeholder: null,
  placeholderSmall: null,
  mobile: undefined,
  onResultClickOptions: {},
  autoCompleteStyles: null,
  types: [],
  forwardedRef: null,
  analyticsVariables: null,
};

export default EntityNavigateSearch;
